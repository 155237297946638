// import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function App() {
	return (
		<div className="App">
      		<header className="App-header">
				<p>Header</p>
	      	</header>
			<div>
				<img id='hero-image' src='./img/nature/DesertScape1.jpg' alt='' />
			</div>
			<div>
				<p>Sub-Content 1</p>
			</div>
			<div>
				<p>Sub-Content 2</p>
			</div>
			<div>
				<p>Sub-Content 3</p>
			</div>
			<footer>
				<p>Footer</p>
			</footer>
    	</div>
  	);
}

export default App;
